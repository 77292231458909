import React from 'react'
import Title from '../../components/Title'

const Products = () => {
  return (
    <div class='container Products'>
        <Title headline='Alle produkter vises her'/>
    </div>
  )
}

export default Products