import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-light text-center text-lg-start">
  
  <div className="text-center p-3">
    © 2020 Copyright
  </div>
  
</footer>
  )
}

export default Footer