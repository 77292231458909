import React from 'react'
import Header from '../layout/Header';

const Home = () => {
return (
    <div className='Home container'>
    <h1>Home</h1>

    <Header/>
    </div>
)
}

export default Home